@import url('https://fonts.googleapis.com/css2?family=Gothic+A1:wght@600&display=swap');
.shopcard__container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.shopcard__background{
position: relative;
/* background-image: url("../../assets/category/bekleidung.jpg"); */
height: 226.2px;
width: 100%;
background-repeat: no-repeat;
background-position: center;
background-size: cover;
-webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
.shopcard__container h2{
    font-family: 'Gothic A1', sans-serif;
    line-height: 129%;
    font-size: 1vw;
    margin-top: 5%;
}
.shopcard__container p{
    letter-spacing: 0.5px;
    font-size: 1vw;
    line-height: 150%;
    color: #929395;
    margin-top: 5%;
    text-align: center;
}
.shop__link{
background-color:#FF7379 ;
text-decoration: none;
color: white;
display: flex;
align-items: center;
justify-content: center;
height: 45.24px;
width: 14.037433155080214vw;
font-size: 1vw;
font-weight: 700;
border-radius: 57px;
margin-top: 5%;
}
.shopcard__address{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
@media only screen and (min-width: 931px) and (max-width: 1025px){
    .shopcard__background{
        height: calc(226.2px - 2.5vh) 
    }
    .shop__link{
        height: calc(45.24px - 0.5vh);
        width: 12vw;
        }
} 
