.form__component{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: baseline;
    width: 80%;
}
h4{
    color: #696F79;font-weight: 500;
    font-size: 18px;
}
.form__namenumber{
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.form__input{
background: #FFFFFF;
border: 1px solid #1565D8;
box-sizing: border-box;
box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.11);
border-radius: 6px;
width:  30vw;
height: 6vh;
outline-width: 0;
margin-bottom: 5vh;
padding: 1%;
}
.form__input:focus{
    border: 1px solid #FF7379;
}
.PhoneInputCountry{
    min-width: 25%!important;
}
.PhoneInputInput{
    border: none!important;
    outline-width: 0!important;
}
.form__weekcontainer{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 50%;
    flex-direction: column;
    margin-bottom: 5vh;
}
.form__weekscontainer h4{
    color: #525252;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 1vh;
}

.shop__opens{
    margin-bottom: 2vh;
}
.form__weeks {
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: center;
}
.form__timecontainer{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 40%;
    align-items: flex-start;
    margin-bottom: 5vh;
}
.form__aboutshop{
background: #FFFFFF;
border: 1px solid #1565D8;
box-sizing: border-box;
box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.11);
border-radius: 6px;
width:  100%;
height: 20vh;
outline-width: 0;
margin-bottom: 5vh;
padding-left: 1%;
}
.form__websitelink{
    height: 6vh;
}
.submit__btn{
background: #FF7379;
border-radius: 6px;
color: white;
border: none;
outline-width: 0;
height: 5vh;
width: 5vw;
cursor: pointer;
}
.form__weekscontainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
}
.form__servicecontainer{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 5vh;
}
.form__servicecontainer h4{
    color: #525252;
    font-weight: 500;
    font-size: 16px;
    margin-right: 2vh;
}
.form__services{
    display: flex;
    justify-content: center;
    align-items: center;
}
.form__error{
height: 20vh;
display: flex;
align-items: center;
justify-content: center;
width: 100%;
font-size: large;
color: red;
}
@media only screen and (max-width: 1030px) {
    .form__namenumber{
        flex-direction: column;
        justify-content: center;
        width: 100%;
        align-items: center;
    }
    .form__input{
        width: 100%;
    }
    .location__container{
        width: 100%;
    }
    .form__weekcontainer{
        margin-top: 5vh;
        width: 100%;
    }
    .form__timecontainer{
        width: 100%;
    }
    .submit__btn{
        width: 100px;
    }
  }
  @media only screen and (max-width: 945px){
      .form__servicecontainer{
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;
      }
      .form__services{
          width: 50%;
          display: flex;
          justify-content: space-between;
      }
      
  }
  @media only screen and (max-width: 560px){
    .form__services{
        width: 80%;
    }
    
}