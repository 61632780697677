@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap');
.about__container{
    padding-top: 10vh;
    padding-left: 5vw;
    padding-right: 5vw;
    padding-bottom: 5vh;
    display: flex;
    flex-direction: column;
    min-height: 80vh;
}

.about__container h4{
    font-size: 50px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    text-align: center;
}
.about__container h5{
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 30px;
    color: rgb(0, 0, 0);
    margin-top: 5vh;
    text-align: center;
}
.about__container p{
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    font-size: 25px;
    text-align: justify;
    color: rgb(27, 27, 27); 
    margin-top: 8vh;
}
.register_shopcontainer{
    margin-top: 5vh;
    height: 5vh;
    width: 100%;
    display: flex;
    justify-content: center;
}
.register__link{
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 30%;
    width: 30%;
    text-decoration: none;
    background-color: #FF7379;
    color: white;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    border-radius: 6px;
}
@media only screen and (max-width: 932px) {
    .about__container  h4{
        font-size:30px;
    }
    .about__container h5{
        font-size: 25px;
    }
    .about__container p{
        font-size: 20px;
    }
    .register__link{
        min-width: 50%;
        font-size: 13px;
    }
  }