
.filter__container{
    width: 100%;
    font-size: 16px;
}
.category{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2vh;
}
/* F6F7F8 */
.category__section{
padding: 10%;
margin-top: 2vh;
background: #F6F7F8;
}
.category__section h4{
    font-size: 16px;
    color: #22262A;
}
.range__component{
    -webkit-appearance: none;  /* Override default CSS styles */
  appearance: none;
    width: 100%;
    background: #d3d3d3; /* Grey background */
    outline: none; /* Remove outline */
    opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
    -webkit-transition: .2s; /* 0.2 seconds transition on hover */
    transition: opacity .2s;
    height: 0.5vh;
}
.category h4{
    height: 18px;
   text-transform: capitalize;
}
.range__component::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 15px; /* Set a specific slider handle width */
    height: 15px; /* Slider handle height */
    border-radius: 100%;
    background: #4CAF50; /* Green background */
    cursor: pointer; /* Cursor on hover */
  }
  .sort__select{
      border: none;
      outline-width: 0;
      height: 30px;
  }
  .sort{
      display: flex;
      justify-content: space-between;
      align-items: center;
  }
  .sort h4{
      height: 100%!important;
      margin-bottom: 0;
  }
  .sort__options{
      border: none;
      height: 30px;
  }
  @media only screen and (max-width: 1245px) {
   .category__section h4{
       font-size: 12px;
   }
  }
  @media only screen and (max-width: 800px) {
    .category__section h4{
        font-size: 10px;
    }
    .sort__select{
        height: 20px;
        font-size: 10px;
    }
    .sort__options{
        height: 20px;
        font-size: 10px!important;
    }
  }
  @media only screen and (max-width: 540px) {
    .category__section h4{
        font-size: 8px;
    }
  }