@import url('https://fonts.googleapis.com/css2?family=Gothic+A1:wght@500&family=Poppins:wght@200;300;400&display=swap');
.shop_background{
    /* background-image: url("../../assets/shop__background.png"); */
    height: 70vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.shop__info{
    margin-top: 5vh;
    padding-left: 5vw;
    padding-right: 5vw;
    
}
.shop__info h2{
    font-family: 'Gothic A1', sans-serif;
    font-size: 50px;
    font-weight: 600;
    color: #555050;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.shop__info p{
    margin-top: 2vh;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    line-height: 36px;
    color: #686868;  
}
.shop__category{
background: rgba(51,160, 255, 0.1);
color: #1F7AC9;
font-family: 'Poppins', sans-serif;
font-size: 20px;
min-width: 15%;
text-align: center;
font-weight: 300;
text-transform: capitalize;
}
.shop__contact{
    display: flex;
    padding-left: 5vw;
    padding-right: 5vw;
    margin-top: 5vh;
    justify-content: space-between;
    align-items: center;
}
.shop__contact .contact__mail{
    display: flex;
    background: #FF7379;
    border-radius: 5px;
    height: 8.355437665782494vh;
    align-items: center;
    justify-content: space-around;
    padding: 5px;
    min-width: 25%;
}
.website__link{
    display: flex;
    justify-content: center;
    align-items: center;
}
.website__link a{
    height: 5vh;
    text-decoration: none;
    color: white;
    background-color: #FF7379;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
}
.shop__contact .contact__mail h2{
    font-family: 'Poppins', sans-serif;
    font-weight: 200;
    color: white;
    font-size: 16px;
}
.contact__backgroundmail{
    height: 70%;
    width: 20%;
    background-image: url("../../assets/email__icon.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}
.contact__backgroundphone{
    height: 70%;
    width: 20%;
    background-image: url("../../assets/phone__icon.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}
.contact__backgroundtime{
    height: 70%;
    width: 20%;
    background-image: url("../../assets/clock__icon.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}
.shop__about,.shop__location{

    margin-top: 10vh;
    padding-left: 5vw;
    padding-right: 5vw;
 
}
.shop__about h3,.shop__location h3{
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 28px;
    line-height: 35px;
    color: #3D3B3B;
}
.shop__description{
margin-top: 2vh;
background: #EEEEEE;
font-weight: 300;
padding: 5vh;
font-size: 18px;
line-height: 123.5%;
letter-spacing: 0.01em;
color: #000000;
/* letter-spacing: 1%; */
}
.map__container{
    min-height:60vh ;
    margin-top: 5vh;
}
@media only screen and (max-width: 1024px) {
    .shop__contact .contact__mail h2{
        font-size: 12px;
    }
    .contact__backgroundmail,
    .contact__backgroundphone,
    .contact__backgroundtime{
        height: 50%;
    }
  }
  @media only screen and (max-width: 980px) {
    .shop__contact .contact__mail h2{
        font-size: 12px;
    }
    .contact__backgroundmail,
    .contact__backgroundphone,
    .contact__backgroundtime{
        height: 50%;
    }
    .shop__contact .contact__mail{
        height: 6vh;
        width: 30%;
    }
    
  }
  @media only screen and (max-width: 880px) {
   
    .shop__category{
        font-size: 20px;
        min-width: 15%;
        
        }
  }
  @media only screen and (max-width: 687px) {
   
    .shop__contact{
    flex-direction: column;
    justify-content: space-between;
    height: 20vh;
        }
        .contact__backgroundmail,
        .contact__backgroundphone,
        .contact__backgroundtime{
            height: 50%;
        
        }
        .shop__contact .contact__mail{
            font-size: 15px;
            width: 80%;
            justify-content: space-between;
        }
  }
  @media only screen and (max-width: 607px) {
   
    .shop__category{
        font-size: 20px;
        min-width: 20%;
        
        }
        .shop__info h2{
            font-size: 30px;
        }
        .shop__category{
            font-size: 15px;
        }
        .shop__info p{
            font-size: 14px;
        }
        .shopservice__container h4{
            font-size: 15px;
        }
        .shop__about h3{
            font-size: 20px;
        }
        .shop__description p{
            font-size: 12px;
        }
        .shop__location h3{
            font-size: 20px;
        }
  }