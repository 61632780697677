@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap');

.contact__container{
    padding-top: 10vh;
    padding-left: 5vw;
    padding-right: 5vw;
    padding-bottom: 5vh;
    display: flex;
    flex-direction: column;
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    min-height: 50vh;
    text-align: justify;
    color: rgb(19, 19, 19);
}
.contact__container h1{
    text-align: center;
    font-size: 40px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    color: #696F79;
    text-transform: uppercase;
    margin-bottom: 5vh;
}


.contact__container h3{
    color: #696F79;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    text-align: center;
    margin-top: 1vh;
    font-weight: 400;
}
@media only screen and (max-width: 932px) {
    .contact__container  h1{
        font-size:30px;
    }
    .contact__container h3{
        font-size: 25px;
    }

  }
  @media only screen and (max-width: 590px) {
    .contact__container  h1{
        font-size:25px;
    }
    .contact__container h3{
        font-size: 20px;
    }

  }