.footer__container {
  /* min-height: 60vh; */
  margin-top: 5vh;
  height: 60vh;
  background-color: #1c2331;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: space-between;
}
.footer__sociallinks {
  display: flex;
  justify-content: space-between;
  padding-left: 5vw;
  padding-right: 5vw;
  background-color: #ff7379;
  color: white;
  height: 15%;
  align-items: center;
}
.footer__sociallinks h3{
  font-size: 18px;
}
.icon__container {
  width: 10%;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
}
.icon__container a {
  text-decoration: none;
  color: white;
  cursor: pointer;
  font-size: 18px;
}
.footer__mainbody {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  height: 80%;
  padding: 5vh 5vw;
}
.main__bodyabout {
  display: flex;
  flex-direction: column;
  justify-content: start;
  max-width: 25%;
  height: 80%;
}
.main__bodyabout h4{
  font-size: 18px;
}
.about__headerfooter {
  color: rgb(199, 199, 199);
}
.about__para {
  color: #696f79;
  text-decoration: none;
  margin-top: 3vh;
  font-size: 16px;
}
.footer__copyright {
  display: flex;
  justify-content: center;
  font-size: 1.3vw;
  align-items: center;
  padding-left: 5vw;
  padding-right: 5vw;
  color: rgb(77, 77, 77);
  height: 15%;
  border-top: 1px solid black;
}
.about__link{
  text-decoration: none;
  color: #696f79;
  text-align: center;
  font-size: 16px;
}
.about__link:hover{
  color: white;
}
.about__img{
  background-image: url("../../assets/black-marble2.png");
  height: 15vh;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.about__imgK{
  background-image: url("../../assets/Kematen.png");
  height: 15vh;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
@media only screen and (max-width: 970px) {
.footer__container{
  min-height: 40vw!important;
  max-height: 50vw!important;
}}
@media only screen and (max-width: 760px) {
  .about__para{
    font-size: 2vw!important;
  }
  .footer__container{
    min-height: 40vw!important;
    max-height: 65vw!important;
  }
  .about__headerfooter{
    font-size: 2.5vw!important;
  }
  .footer__sociallinks h3{
    font-size: 2.5vw!important;
  }
}
@media only screen and (max-width: 590px) {

  .footer__copyright{
    font-size: 2vw;
  }


}