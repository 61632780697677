@import url('https://fonts.googleapis.com/css2?family=Gothic+A1:wght@700&display=swap');
.navbar__container{
display: flex;
align-items: center;
justify-content: space-between;
height: 16.578249336870027vh;
padding-right: 5%;
position: relative;
}
.logo__link{
    text-decoration: none;
}
.logo__container{
    display: flex;
    align-items: center;
    padding-left: 5vw;
    height: 100%;
}
.navbar__container{
    height: 20vh;
}
.logo__container img{
    max-height: 100%;
}
.logo__container h4{
    margin-left: 2vw;
    font-family:'Gothic A1', sans-serif;
    font-weight: 700;
    color: #EC1F27;
    font-size: 150%;
}
.shop__searchbar{
    border: none;
    height: 100%;
    font-weight: normal;
    font-size: 20px;
    color: #656363;
    width: 85%;
    outline-width: 0;
}
.searchbar__container{
    height: 30%;
    border-bottom: 1px solid black;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
.navbar__form{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.navright{
    display: flex;
    width: 30%;
    min-width: 30%;
    justify-content: space-between;
    align-items: center;
}
.navbar__left{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    min-width: 50%;
}
.marketplace__btn{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-decoration: none;
    color: rgb(0, 0, 0);
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    border-radius: 6px;
    height: 5vh;
    cursor: pointer;
}
.marketplace__btn:hover{
    color: #FF7379;
}
@media only screen and (max-width: 1262px) {
    .marketplace__btn{
        font-size: 12px;
    }
    .searchbar__container{
        margin-left: 2vw;
    }
    .shop__searchbar{
       font-size: 0.9em;
    }
    .searchbar__container{
        width: 20vw;
    }
    .navbar__container{
        height: 18vh;
    }
  }
  @media only screen and (max-width: 1022px) {
    .shop__searchbar{
        font-size: 0.7em;
     }
     .logo__container{
        height: 50%;
        padding-left:1vw ;
    }
    .navbar__container{
        height: 15vh!important;
    }
    .logo__container h4{
        font-size: 100%;
    }
    .navright{
        width: 40%;
    }
  }
  @media only screen and (max-width: 830px) {
    .logo__container h4{
        font-size: 80%;
    }
    .logo__container img{
        height: 50px;
    }
    .navbar__container{
        height: 10vh!important;
    }
}
@media only screen and (max-width: 765px) {
    .logo__container h4{
        font-size: 70%;
    }
    .logo__container img{
        height: 50px;
    }
    .searchbar__container{
        margin-left: 0vw;
    }
    .navbar__left{
        width: 30%;
    }
    .navright{
        min-width: 45%;;
    }
}
@media only screen and (max-width: 667px) {
    .logo__container h4{
        font-size: 60%;
    }
    .logo__container img{
        height: 30px;
    }
    .searchbar__container{
        margin-left: 0vw!important;
    }
    .navbar__left{
        min-width: 40%;
    }
    .searchbar__container{
        width: 15vw;
    }
    .marketplace__btn{
        font-size: 10px;
    }
    .navright{
        min-width: 45%;;
    }
   
}
@media only screen and (max-width: 567px) {
    .logo__container h4{
        font-size: 60%;
    }
    .logo__container img{
        height: 30px;
    }
    .searchbar__container{
        margin-left: 0vw!important;
    }
    .navbar__left{
        min-width: 40%;
    }
    .searchbar__container{
        width: 15vw;
    }
    .marketplace__btn{
        font-size: 10px;
    }
    .navright{
        min-width: 50%;;
    }
    .navbar__form{
        font-size: 12px;
    }
   
}
@media only screen and (max-width: 498px) {
    .logo__container h4{
        font-size: 60%;
    }
    .logo__container img{
        height: 25px;
    }
    .searchbar__container{
        margin-left: 0vw!important;
    }
    .navbar__left{
        min-width: 40%;
    }
    .searchbar__container{
        width: 15vw;
    }
    .marketplace__btn{
        font-size: 10px;
    }
    .navright{
        min-width: 50%;;
    }
    .navbar__form{
        font-size: 12px;
    }
   
}
@media only screen and (max-width: 450px) {
    .logo__container h4{
        font-size: 100%;
    }
    .logo__container img{
        height: 40px;
    }
    .logo__container{
        margin-right: 17vw;
    }
   .navbar__container{
       flex-direction: column;
       padding-bottom: 5px;
       height: 20vw!important;
   }
   .navbar__left{
       width: 85%;
       justify-content: flex-start;
   }
   .navright{
       width: 80%;
       justify-content: flex-end;
   }
   .searchbar__container{
       width: 30vw;
   }
   .navbar__form{
       font-size: 20px;
   }
   .marketplace__btn{
       font-size: 10px;
       /* margin-right: 2vw; */
   }
   
}