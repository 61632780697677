@import url(https://fonts.googleapis.com/css2?family=Gothic+A1:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Gothic+A1:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Gothic+A1:wght@500&family=Poppins:wght@200;300;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.helper{
  --reacttour-accent : #5cb7b7;
  line-height: 1.3;
  color: #2d2323;
}
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* border: 1px solid #f00 !important; */
  outline-width: 0!important;
}

body{
  width: 100%;
}

.navbar__container{
display: flex;
align-items: center;
justify-content: space-between;
height: 16.578249336870027vh;
padding-right: 5%;
position: relative;
}
.logo__link{
    text-decoration: none;
}
.logo__container{
    display: flex;
    align-items: center;
    padding-left: 5vw;
    height: 100%;
}
.navbar__container{
    height: 20vh;
}
.logo__container img{
    max-height: 100%;
}
.logo__container h4{
    margin-left: 2vw;
    font-family:'Gothic A1', sans-serif;
    font-weight: 700;
    color: #EC1F27;
    font-size: 150%;
}
.shop__searchbar{
    border: none;
    height: 100%;
    font-weight: normal;
    font-size: 20px;
    color: #656363;
    width: 85%;
    outline-width: 0;
}
.searchbar__container{
    height: 30%;
    border-bottom: 1px solid black;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
.navbar__form{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.navright{
    display: flex;
    width: 30%;
    min-width: 30%;
    justify-content: space-between;
    align-items: center;
}
.navbar__left{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    min-width: 50%;
}
.marketplace__btn{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-decoration: none;
    color: rgb(0, 0, 0);
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    border-radius: 6px;
    height: 5vh;
    cursor: pointer;
}
.marketplace__btn:hover{
    color: #FF7379;
}
@media only screen and (max-width: 1262px) {
    .marketplace__btn{
        font-size: 12px;
    }
    .searchbar__container{
        margin-left: 2vw;
    }
    .shop__searchbar{
       font-size: 0.9em;
    }
    .searchbar__container{
        width: 20vw;
    }
    .navbar__container{
        height: 18vh;
    }
  }
  @media only screen and (max-width: 1022px) {
    .shop__searchbar{
        font-size: 0.7em;
     }
     .logo__container{
        height: 50%;
        padding-left:1vw ;
    }
    .navbar__container{
        height: 15vh!important;
    }
    .logo__container h4{
        font-size: 100%;
    }
    .navright{
        width: 40%;
    }
  }
  @media only screen and (max-width: 830px) {
    .logo__container h4{
        font-size: 80%;
    }
    .logo__container img{
        height: 50px;
    }
    .navbar__container{
        height: 10vh!important;
    }
}
@media only screen and (max-width: 765px) {
    .logo__container h4{
        font-size: 70%;
    }
    .logo__container img{
        height: 50px;
    }
    .searchbar__container{
        margin-left: 0vw;
    }
    .navbar__left{
        width: 30%;
    }
    .navright{
        min-width: 45%;;
    }
}
@media only screen and (max-width: 667px) {
    .logo__container h4{
        font-size: 60%;
    }
    .logo__container img{
        height: 30px;
    }
    .searchbar__container{
        margin-left: 0vw!important;
    }
    .navbar__left{
        min-width: 40%;
    }
    .searchbar__container{
        width: 15vw;
    }
    .marketplace__btn{
        font-size: 10px;
    }
    .navright{
        min-width: 45%;;
    }
   
}
@media only screen and (max-width: 567px) {
    .logo__container h4{
        font-size: 60%;
    }
    .logo__container img{
        height: 30px;
    }
    .searchbar__container{
        margin-left: 0vw!important;
    }
    .navbar__left{
        min-width: 40%;
    }
    .searchbar__container{
        width: 15vw;
    }
    .marketplace__btn{
        font-size: 10px;
    }
    .navright{
        min-width: 50%;;
    }
    .navbar__form{
        font-size: 12px;
    }
   
}
@media only screen and (max-width: 498px) {
    .logo__container h4{
        font-size: 60%;
    }
    .logo__container img{
        height: 25px;
    }
    .searchbar__container{
        margin-left: 0vw!important;
    }
    .navbar__left{
        min-width: 40%;
    }
    .searchbar__container{
        width: 15vw;
    }
    .marketplace__btn{
        font-size: 10px;
    }
    .navright{
        min-width: 50%;;
    }
    .navbar__form{
        font-size: 12px;
    }
   
}
@media only screen and (max-width: 450px) {
    .logo__container h4{
        font-size: 100%;
    }
    .logo__container img{
        height: 40px;
    }
    .logo__container{
        margin-right: 17vw;
    }
   .navbar__container{
       flex-direction: column;
       padding-bottom: 5px;
       height: 20vw!important;
   }
   .navbar__left{
       width: 85%;
       justify-content: flex-start;
   }
   .navright{
       width: 80%;
       justify-content: flex-end;
   }
   .searchbar__container{
       width: 30vw;
   }
   .navbar__form{
       font-size: 20px;
   }
   .marketplace__btn{
       font-size: 10px;
       /* margin-right: 2vw; */
   }
   
}

.locationbar__container{
    position: relative;
    background: #FFFFFF;
    border: 1px solid #FF7379;
    box-sizing: border-box;
    box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.11);
    border-radius: 6px;
    width:  20vw;
    height: 32%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    outline-width: 0;
    padding-left: 1%;
}

.search__locationbar{
    border: none;
    height: 100%;
    font-weight: normal;
    font-size: 1em;
    color: #656363;
    outline-width: 0;
   width: 100%;
    position: relative;
    border-radius: 6px;
    
}
.wrapper{
    height: 100%;
    width: 85%;
    max-width: 90%;
}

.suggestion__container{
    background-color: white;
    max-width: 25vw;
    width: 25vw;
    padding:5px;
}
@media only screen and (max-width: 1024px) {
    .search__locationbar{
       font-size: 0.7em;
        
    }
  }
  @media only screen and (max-width: 805px) {
    .search__locationbar{
        font-size: 0.6em;
     }
  }
  @media only screen and (max-width: 688px) {
    .search__locationbar{
        font-size: 0.5em;
     }
  }
  @media only screen and (max-width: 560px) {
    .locationbar__container{
        width: 25vw;
     }
  }
  @media only screen and (max-width: 415px) {
    .locationbar__container{
        width: 25vw;
     }
     .search__locationbar{
        font-size: 0.4em;
     }
  }
.locate__btn{
    border: none;
    background-color: transparent;
    cursor: pointer;
    height: 100%;
   outline-width: 0;
}


/* .drop__down{
    position: absolute;
    width: 20vw;
    min-width: 20vw;
    right: 4vw;
    top:15vh;
    padding: 2vw;
    background-color: #1c2331;
    color: white;
    z-index: 2;
}
.navbar__linkcontainer{
display: flex;
flex-direction: column;
justify-content: space-around;
height: 100%;
width: 100%;
}
.marketplace__btn{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-decoration: none;
    background-color: #FF7379;
    color: white;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    border-radius: 6px;
    height: 5vh;
    cursor: pointer;
    margin-top: 5vh;
}
.navlink__btn{
    display: flex;  
    justify-content: center;
    align-items: center;
    width: 100%;
    text-decoration: none;
    color: white;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    height: 5vh;
    cursor: pointer;
    margin-bottom: 5px;
    border-bottom: 1px solid #FF7379;
}
.navlink__btn:hover{
    color: #FF7379;
} */
.locationbar__container{
    position: absolute;
    z-index: 2;
    /* height: 7vh;
    width: 20vw; */
    top: 120%;
    left: 40%;
}
.navbar__menu{
    display: flex;
    justify-content: center;
    align-items: center;
}
.menu__container{
    margin-top: 5vh;
    width: 60%;
    position: relative;
    
}
.home__category{
    text-transform: capitalize;
}
.menu__categories{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.menu__container button{
    text-transform: capitalize;
    font-family: Poppins;
    font-size: 22px;
    color: #FFFFFF;
    background-color: transparent;
    outline-width: 0px;
    cursor: pointer;
    text-decoration: none;
    border: none;
}
.menu__container button:hover{
color: #FF7379;
}
.menu__allcats{
    position: absolute;
    bottom: -75vh;
    right: 0;
    background-color: white;
    padding: 5%;
    width: 60vw;
    height: 70vh;
    min-height: 30vh;
    z-index: 2;
    display: flex;
    justify-content: space-around;
    box-shadow: 8px 11px 62px -19px rgba(0,0,0,0.46);
    -webkit-box-shadow: 8px 11px 62px -19px rgba(0,0,0,0.46);
    -moz-box-shadow: 8px 11px 62px -19px rgba(0,0,0,0.46);
}
.menu__allcats button{
    color: #262626;
    text-align: left;
}
.row{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
}
@media only screen and (max-width: 1262px) {
    .menu__container button{
        font-size: 16px;
    }
    .menu__container{
        width: 80%;
     }
  }
@media only screen and (max-width: 932px) {
    .menu__container button{
        font-size: 16px;
    }
    
  }
  @media only screen and (max-width: 690px) {
    .menu__container button{
        font-size: 13px;
    }
  }
  @media only screen and (max-width: 690px) {
   .menu__allcats{
       flex-direction: column;
       width: 35vw;
       min-height: 40vh;
       padding: 4%;
       margin-right: 1%;
   }
   .menu__container{
    width: 100%;
   }
  }
  @media only screen and (max-width: 506px) {
  
    .menu__container button{
        font-size: 10px;
        justify-content: space-evenly;
    }
    .menu__container{
        width: 95%;
     }
   }
   @media only screen and (max-width: 400px) {
  
    .menu__container button{
        font-size: 8px;
        justify-content: space-evenly;
    }
    .menu__container{
        width: 95%;
     }
   }
 
.md-ui.component-pagination {
    list-style: none;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .md-ui.component-pagination li {
    border-radius: 100%;
    color: #212121;
    display: inline-block;
    margin-left: 2vw;
    margin-right: 2vw;
    transition: 0.15s ease-in;
    cursor: default;
  }
  .current-number,.pagination-arrow{
    cursor: pointer!important;
  }
  .pagination-number{
    background: rgba(233, 30, 98, 0.226);
  }

  .md-ui.component-pagination .pagination-number,
  .md-ui.component-pagination .pagination-arrow i {
    vertical-align: middle;
  }
  .md-ui.component-pagination .pagination-number {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    text-align: center;
    line-height: 24px;
    min-width: 40px;
    padding: 8px 0;
  }
  .md-ui.component-pagination .pagination-number.current-number {
    background:  #FF7379;
    color: white;
  }
  .md-ui.component-pagination .pagination-arrow i {
    font-size: 24px;
    padding: 8px;
  }
.background_none{
  background: none!important;
}
.shopcard__container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.shopcard__background{
position: relative;
/* background-image: url("../../assets/category/bekleidung.jpg"); */
height: 226.2px;
width: 100%;
background-repeat: no-repeat;
background-position: center;
background-size: cover;
border-radius: 10px;
}
.shopcard__container h2{
    font-family: 'Gothic A1', sans-serif;
    line-height: 129%;
    font-size: 1vw;
    margin-top: 5%;
}
.shopcard__container p{
    letter-spacing: 0.5px;
    font-size: 1vw;
    line-height: 150%;
    color: #929395;
    margin-top: 5%;
    text-align: center;
}
.shop__link{
background-color:#FF7379 ;
text-decoration: none;
color: white;
display: flex;
align-items: center;
justify-content: center;
height: 45.24px;
width: 14.037433155080214vw;
font-size: 1vw;
font-weight: 700;
border-radius: 57px;
margin-top: 5%;
}
.shopcard__address{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
@media only screen and (min-width: 931px) and (max-width: 1025px){
    .shopcard__background{
        height: calc(226.2px - 2.5vh) 
    }
    .shop__link{
        height: calc(45.24px - 0.5vh);
        width: 12vw;
        }
} 

.home__container{
    position: relative;
    padding-top: 20vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column  ;
    max-width: 100vw;
}
.home__backgroundImage{
    /* background-image: url("../assets/category_svg_bg.svg"); */
    height: 76.52519893899205vh;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    max-width: 100%;
    z-index: -2;
    position: absolute;
    background-position: center;
    top:0;
    left: 0;
}
.home__decor{
    position: absolute;
    z-index: -1;
    background: linear-gradient(359.9deg, #000000 14.14%, rgba(0, 0, 0, 0) 102.14%);
    height: 76.52519893899205vh;
    width: 100%;
    max-width: 100%;
    top:0;
    left: 0;
}
.home__category{
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 400%;
    color: white;
    text-align: center;
}
.home__herocontainer{
    width: 100%;
    max-width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow-x: hidden;
}
.home__cardcontainer{
   width: 78.34224598930481vw;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 5vh;
    row-gap: 5vh;
    grid-column-gap: 5.481283422459893vw;
    -webkit-column-gap: 5.481283422459893vw;
            column-gap: 5.481283422459893vw;
    min-height: 40vh;
}
.home__loadmore{
font-weight: 500;
font-size: 20px;
line-height: 30px;
color: #FF7379;
border: none;
border-bottom: 1px solid #FF7379;
background-color: transparent;
cursor: pointer;
outline-width: 0;
}
.contribute_shopcontainer{
    margin-top: 5vh;
    height: 5vh;
    width: 80%;
    display: flex;
    justify-content: flex-end;
}
.contribute__link{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    text-decoration: none;
    background-color: #FF7379;
    color: white;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    border-radius: 6px;
}
.home__selectlocation{
    display: flex;
    flex-direction: column;
    background-color: white;
    justify-content: space-around;
    align-items: center;
    width: 80vw;
    height: 30vh;
    box-shadow: 2px -1px 42px -6px rgba(0,0,0,0.75);
-webkit-box-shadow: 2px -1px 42px -6px rgba(0,0,0,0.75);
-moz-box-shadow: 2px -1px 42px -6px rgba(0,0,0,0.75);
}
.home__selectlocation h4{
    
}
@media only screen and (max-width: 1025px){
    .home__backgroundImage,
    .home__decor{
        height: 50vh;
    }
  } 
@media only screen and (max-width: 932px) {
    .home__category{
       
        font-size: 300%;
        
    }
  }
  @media only screen and (max-width: 690px) {
    .home__category{
       
        font-size: 200%;
        
    }
    .home__cardcontainer{
        grid-template-columns: 1fr 1fr;
    }
    .home__backgroundImage{
        height: 50vh;
      
    }
    .home__decor{
        height: 50vh;
    }
  }
  @media only screen and (max-width: 767px) {
    .contribute__link{
       font-size: 1.5vw;
    }
  }
  @media only screen and (max-width: 477px) {
    .contribute__link{
       font-size: 1.8vw;
    }
  }

.loading__container{
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loading__bg{
    background-image: url(/static/media/loader.ae65c16d.png);
    background-size: contain;
    height: 50vh;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
}
.slide__bg1{
    background-image: url(/static/media/slide__1.730a62d2.jpeg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.slide__bg2{
    background-image: url(/static/media/slide__2.fb86692c.jpeg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.slide__bg3{
    background-image: url(/static/media/slide__3.e0ff6db3.jpeg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.slide__bg4{
    background-image: url(/static/media/slide__4.19fb7020.jpeg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.shopservice__container{
    margin-left: 5vw;
    margin-right: 5vw;
}
.shopservice__container h4{
color: #696F79;
font-weight: bold;
font-size: 18px;
margin-top: 5vh;
}
.service__container{
    display: flex;
    justify-content: space-between;

}
.service__details{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 5vh;
}
.service__icons{
    font-size: 1.5rem;
    margin-left: 1vw;
    color: green;
}
.service__preicon{
    color: #0070C0;
}
.service__title{
    margin-left: 1vw;
    
}
@media only screen and (max-width: 995px) {
    .service__container{
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .service__title{
        font-size: 1.8vw;
    }
    }
.shop_background{
    /* background-image: url("../../assets/shop__background.png"); */
    height: 70vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.shop__info{
    margin-top: 5vh;
    padding-left: 5vw;
    padding-right: 5vw;
    
}
.shop__info h2{
    font-family: 'Gothic A1', sans-serif;
    font-size: 50px;
    font-weight: 600;
    color: #555050;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.shop__info p{
    margin-top: 2vh;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    line-height: 36px;
    color: #686868;  
}
.shop__category{
background: rgba(51,160, 255, 0.1);
color: #1F7AC9;
font-family: 'Poppins', sans-serif;
font-size: 20px;
min-width: 15%;
text-align: center;
font-weight: 300;
text-transform: capitalize;
}
.shop__contact{
    display: flex;
    padding-left: 5vw;
    padding-right: 5vw;
    margin-top: 5vh;
    justify-content: space-between;
    align-items: center;
}
.shop__contact .contact__mail{
    display: flex;
    background: #FF7379;
    border-radius: 5px;
    height: 8.355437665782494vh;
    align-items: center;
    justify-content: space-around;
    padding: 5px;
    min-width: 25%;
}
.website__link{
    display: flex;
    justify-content: center;
    align-items: center;
}
.website__link a{
    height: 5vh;
    text-decoration: none;
    color: white;
    background-color: #FF7379;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
}
.shop__contact .contact__mail h2{
    font-family: 'Poppins', sans-serif;
    font-weight: 200;
    color: white;
    font-size: 16px;
}
.contact__backgroundmail{
    height: 70%;
    width: 20%;
    background-image: url(/static/media/email__icon.3cf02c57.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}
.contact__backgroundphone{
    height: 70%;
    width: 20%;
    background-image: url(/static/media/phone__icon.f9c11cbf.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}
.contact__backgroundtime{
    height: 70%;
    width: 20%;
    background-image: url(/static/media/clock__icon.5e9b2a4e.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}
.shop__about,.shop__location{

    margin-top: 10vh;
    padding-left: 5vw;
    padding-right: 5vw;
 
}
.shop__about h3,.shop__location h3{
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 28px;
    line-height: 35px;
    color: #3D3B3B;
}
.shop__description{
margin-top: 2vh;
background: #EEEEEE;
font-weight: 300;
padding: 5vh;
font-size: 18px;
line-height: 123.5%;
letter-spacing: 0.01em;
color: #000000;
/* letter-spacing: 1%; */
}
.map__container{
    min-height:60vh ;
    margin-top: 5vh;
}
@media only screen and (max-width: 1024px) {
    .shop__contact .contact__mail h2{
        font-size: 12px;
    }
    .contact__backgroundmail,
    .contact__backgroundphone,
    .contact__backgroundtime{
        height: 50%;
    }
  }
  @media only screen and (max-width: 980px) {
    .shop__contact .contact__mail h2{
        font-size: 12px;
    }
    .contact__backgroundmail,
    .contact__backgroundphone,
    .contact__backgroundtime{
        height: 50%;
    }
    .shop__contact .contact__mail{
        height: 6vh;
        width: 30%;
    }
    
  }
  @media only screen and (max-width: 880px) {
   
    .shop__category{
        font-size: 20px;
        min-width: 15%;
        
        }
  }
  @media only screen and (max-width: 687px) {
   
    .shop__contact{
    flex-direction: column;
    justify-content: space-between;
    height: 20vh;
        }
        .contact__backgroundmail,
        .contact__backgroundphone,
        .contact__backgroundtime{
            height: 50%;
        
        }
        .shop__contact .contact__mail{
            font-size: 15px;
            width: 80%;
            justify-content: space-between;
        }
  }
  @media only screen and (max-width: 607px) {
   
    .shop__category{
        font-size: 20px;
        min-width: 20%;
        
        }
        .shop__info h2{
            font-size: 30px;
        }
        .shop__category{
            font-size: 15px;
        }
        .shop__info p{
            font-size: 14px;
        }
        .shopservice__container h4{
            font-size: 15px;
        }
        .shop__about h3{
            font-size: 20px;
        }
        .shop__description p{
            font-size: 12px;
        }
        .shop__location h3{
            font-size: 20px;
        }
  }

.filter__container{
    width: 100%;
    font-size: 16px;
}
.category{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2vh;
}
/* F6F7F8 */
.category__section{
padding: 10%;
margin-top: 2vh;
background: #F6F7F8;
}
.category__section h4{
    font-size: 16px;
    color: #22262A;
}
.range__component{
    -webkit-appearance: none;  /* Override default CSS styles */
  -moz-appearance: none;
       appearance: none;
    width: 100%;
    background: #d3d3d3; /* Grey background */
    outline: none; /* Remove outline */
    opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */ /* 0.2 seconds transition on hover */
    transition: opacity .2s;
    height: 0.5vh;
}
.category h4{
    height: 18px;
   text-transform: capitalize;
}
.range__component::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 15px; /* Set a specific slider handle width */
    height: 15px; /* Slider handle height */
    border-radius: 100%;
    background: #4CAF50; /* Green background */
    cursor: pointer; /* Cursor on hover */
  }
  .sort__select{
      border: none;
      outline-width: 0;
      height: 30px;
  }
  .sort{
      display: flex;
      justify-content: space-between;
      align-items: center;
  }
  .sort h4{
      height: 100%!important;
      margin-bottom: 0;
  }
  .sort__options{
      border: none;
      height: 30px;
  }
  @media only screen and (max-width: 1245px) {
   .category__section h4{
       font-size: 12px;
   }
  }
  @media only screen and (max-width: 800px) {
    .category__section h4{
        font-size: 10px;
    }
    .sort__select{
        height: 20px;
        font-size: 10px;
    }
    .sort__options{
        height: 20px;
        font-size: 10px!important;
    }
  }
  @media only screen and (max-width: 540px) {
    .category__section h4{
        font-size: 8px;
    }
  }
.search__container{
    display: flex;
    width: 100%;
    min-height: 70vh;
}
.search__aside{
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: row;   
}
.search__spacer{
    height: 15vh;
    background-color: #F6F7F8;
}
.search__components{
    width: 100%;
}
.search__components h2{
padding-top: 5vh;
padding-bottom: 5vh;
font-size: 18px;
line-height: 21px;
text-decoration: underline;
color: #000000;
}
.search__aside{
    padding-top: 5vh;
    padding-left: 2vw;
    padding-right: 2vw;
}
@media only screen and (max-width: 940px) {
    .search__aside{
        width: 35%;
    }
  }
  @media only screen and (max-width: 800px) {
    .search__aside{
        width: 250px;
    }
    .search__spacer{
        height:8vh;
        background-color: #F6F7F8;
    }
  }
.shop__container{
    display: flex;
    position: relative;
    min-height: 30vh;
    height: 30vh;
    margin-bottom: 5vh;
    width: 100%;
}
.shop__image{
    position: relative;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 30%;
    /* background-image: url("../../../assets/search__background.png"); */
}
.shop__discription{
    margin-right: 5vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 5vw;
    width: 100%;
}
.shop__discription h2{
font-family: 'Poppins', sans-serif;
font-weight: 500;
font-size: 24px;
line-height: 36px;
color: #22262A;
}
.shop__discription p{
font-family: 'Poppins', sans-serif;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 18px;

color: #686868;
}

.shop__discription h4{
    overflow: hidden;
 text-overflow: ellipsis;
 display: -webkit-box;
 -webkit-line-clamp: 3;
 -webkit-box-orient: vertical;
    font-size: 14px;
    line-height: 21px;
    color: #262626;
}
.shop__visit{
    margin-top: 2vh;
background: rgba(51,160, 255, 0.1);
color: #1F7AC9;
font-family: 'Poppins', sans-serif;
font-size: 14px;
min-width: 20%;
width: 20%;
text-align: center;
font-weight: 500;
text-decoration: none;
height: 20%;
display: flex;
align-items: center;
justify-content: center;
}
@media only screen and (max-width: 932px) {
   .shop__container{
       min-height: 20vh;
       
   }
   .shop__discription h1{
       font-size: 20px;
   }
   .shop__discription h4{
       font-size: 12px;
   }
   .shop__visit{
       min-width: 40%;
   }
   .shop__image{
    width: 45%;
}
  }

  @media only screen and (max-width: 685px) {
    .shop__container{
        min-height: 20vh;
    }
    .shop__discription h1{
        font-size: 18px;
    }
    .shop__discription p{
        font-size: 10px;
      
    }
    .shop__discription h4{
        font-size: 10px;
        line-height: 18px;
    }
    .shop__visit{
        min-width: 60%;
    }
    
    
   }
   @media only screen and (max-width: 570px) {
    .shop__container{
        min-height: 20vh;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .shop__discription {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
    .shop__discription h1{
        font-size: 18px;
        text-align: center;
    }
    .shop__discription p{
        font-size: 10px;
        text-align: center;
        line-height: 12px;
    }
    .shop__discription h4{
        font-size: 10px;
        text-align: center;
        line-height: 12px;
        width: 80%;
    }
    .shop__visit{
        min-width: 80%;
        text-align: center;
    }
    .shop__image{
        width: 80%;
    }
   
   }
   @media only screen and (max-width: 420px) {
    .shop__discription h1{
        font-size: 15px;
    }
    .shop__discription p{
        font-size: 10px;
        text-align: center;
        line-height: 12px;
    }
    .shop__discription h4{
        font-size: 10px;
        text-align: center;
        line-height: 14px;
    }
    .shop__visit{
        min-width: 80%;
        font-size: 10px;
        text-align: center;
    }
    .shop__image{
        width: 80%;
    }
   
   }
.about__container{
    padding-top: 10vh;
    padding-left: 5vw;
    padding-right: 5vw;
    padding-bottom: 5vh;
    display: flex;
    flex-direction: column;
    min-height: 80vh;
}

.about__container h4{
    font-size: 50px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    text-align: center;
}
.about__container h5{
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 30px;
    color: rgb(0, 0, 0);
    margin-top: 5vh;
    text-align: center;
}
.about__container p{
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    font-size: 25px;
    text-align: justify;
    color: rgb(27, 27, 27); 
    margin-top: 8vh;
}
.register_shopcontainer{
    margin-top: 5vh;
    height: 5vh;
    width: 100%;
    display: flex;
    justify-content: center;
}
.register__link{
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 30%;
    width: 30%;
    text-decoration: none;
    background-color: #FF7379;
    color: white;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    border-radius: 6px;
}
@media only screen and (max-width: 932px) {
    .about__container  h4{
        font-size:30px;
    }
    .about__container h5{
        font-size: 25px;
    }
    .about__container p{
        font-size: 20px;
    }
    .register__link{
        min-width: 50%;
        font-size: 13px;
    }
  }
.team__container{
    padding-top: 10vh;
    padding-left: 5vw;
    padding-right: 5vw;
    padding-bottom: 5vh;
    display: flex;
    flex-direction: column;
    min-height: 80vh;
    text-align: justify;
    color: rgb(19, 19, 19);
}
.team__container h1{
    text-align: center;
    font-size: 40px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    color: #696F79;
    text-transform: uppercase;
    margin-bottom: 5vh;
}
.team__container h6{
    color: rgb(56, 56, 56);
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    text-align: center;
    margin-bottom: 2vh;
}
.team__description h4{
    text-align: center;

}
.team__card{
    width: 250px;
    margin-left: 5vw;
    margin-right: 5vw;
}
.team__peter{
background-image: url(/static/media/peter.c61dca4e.jpg);
height: 250px;
background-size: cover;
background-position: center;
background-repeat: no-repeat;
}
.team__carina{
    background-image: url(/static/media/DCADE1429.bbfc2e73.JPG);
    height: 250px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.team__cardscontainer{
    display: flex;
    justify-content: center;
}
@media only screen and (max-width: 785px) {
    .team__container h6{
        font-size: 12px;

    }
    .team__container h4{
        font-size: 10px;
    }
  }
  @media only screen and (max-width: 630px) {
    .team__container h6{
        font-size: 12px;

    }
    .team__container h4{
        font-size: 10px;
    }
    .team__image{
height: 25vw;
    }
  }
.shipping__container{
    padding-top: 10vh;
    padding-left: 5vw;
    padding-right: 5vw;
    padding-bottom: 5vh;
    display: flex;
    flex-direction: column;
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    min-height: 50vh;
    text-align: justify;
    color: rgb(19, 19, 19);
}
.shipping__container h1{
    text-align: center;
    font-size: 40px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    color: #696F79;
    text-transform: uppercase;
    margin-bottom: 5vh;
}
.shipping__container h6{
    color: rgb(56, 56, 56);
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    text-align: center;
    margin-bottom: 2vh;
}
.shipping__container h2{
    color: rgb(56, 56, 56);
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    text-align: center;
    margin-bottom: 2vh;
    margin-top: 5vh;
}
.shipping__container h3{
    color: #696F79;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    text-align: center;
    margin-top: 1vh;
    font-weight: 400;
    text-transform: capitalize;
}
.imprint__container{
    padding-top: 10vh;
    padding-left: 5vw;
    padding-right: 5vw;
    padding-bottom: 5vh;
    display: flex;
    flex-direction: column;
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    min-height: 80vh;
    text-align: justify;
    color: rgb(19, 19, 19);
}
.imprint__container h1{
    text-align: center;
    font-size: 40px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    color: #696F79;
    text-transform: uppercase;
    margin-bottom: 5vh;
}
.imprint__container h6{
    color: rgb(56, 56, 56);
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    text-align: center;
    margin-bottom: 2vh;
}
.imprint__container h2{
    color: rgb(56, 56, 56);
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    text-align: center;
    margin-bottom: 2vh;
    margin-top: 5vh;
}
.imprint__container h3{
    color: #696F79;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    text-align: center;
    margin-top: 1vh;
    font-weight: 400;
    text-transform: capitalize;
}
.DP__container{
    padding-top: 10vh;
    padding-left: 5vw;
    padding-right: 5vw;
    padding-bottom: 5vh;
    display: flex;
    flex-direction: column;
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    min-height: 80vh;
    text-align: justify;
    color: rgb(19, 19, 19);
}
.DP__container h1{
    text-align: center;
    font-size: 40px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    color: #696F79;
    text-transform: uppercase;
    margin-bottom: 5vh;
}
.DP__container h6{
    color: rgb(56, 56, 56);
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    text-align: center;
    margin-bottom: 2vh;
}
.DP__container h2{
    color: rgb(56, 56, 56);
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    text-align: center;
    margin-bottom: 2vh;
    margin-top: 5vh;
}
.DP__container h3{
    color: #696F79;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    text-align: center;
    margin-top: 1vh;
    font-weight: 400;
    text-transform: capitalize;
}
  @media only screen and (max-width: 590px) {
    .DP__container  h1{
        font-size:25px;
    }
  }
.contact__container{
    padding-top: 10vh;
    padding-left: 5vw;
    padding-right: 5vw;
    padding-bottom: 5vh;
    display: flex;
    flex-direction: column;
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    min-height: 50vh;
    text-align: justify;
    color: rgb(19, 19, 19);
}
.contact__container h1{
    text-align: center;
    font-size: 40px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    color: #696F79;
    text-transform: uppercase;
    margin-bottom: 5vh;
}


.contact__container h3{
    color: #696F79;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    text-align: center;
    margin-top: 1vh;
    font-weight: 400;
}
@media only screen and (max-width: 932px) {
    .contact__container  h1{
        font-size:30px;
    }
    .contact__container h3{
        font-size: 25px;
    }

  }
  @media only screen and (max-width: 590px) {
    .contact__container  h1{
        font-size:25px;
    }
    .contact__container h3{
        font-size: 20px;
    }

  }
.footer__container {
  /* min-height: 60vh; */
  margin-top: 5vh;
  height: 60vh;
  background-color: #1c2331;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: space-between;
}
.footer__sociallinks {
  display: flex;
  justify-content: space-between;
  padding-left: 5vw;
  padding-right: 5vw;
  background-color: #ff7379;
  color: white;
  height: 15%;
  align-items: center;
}
.footer__sociallinks h3{
  font-size: 18px;
}
.icon__container {
  width: 10%;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
}
.icon__container a {
  text-decoration: none;
  color: white;
  cursor: pointer;
  font-size: 18px;
}
.footer__mainbody {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  height: 80%;
  padding: 5vh 5vw;
}
.main__bodyabout {
  display: flex;
  flex-direction: column;
  justify-content: start;
  max-width: 25%;
  height: 80%;
}
.main__bodyabout h4{
  font-size: 18px;
}
.about__headerfooter {
  color: rgb(199, 199, 199);
}
.about__para {
  color: #696f79;
  text-decoration: none;
  margin-top: 3vh;
  font-size: 16px;
}
.footer__copyright {
  display: flex;
  justify-content: center;
  font-size: 1.3vw;
  align-items: center;
  padding-left: 5vw;
  padding-right: 5vw;
  color: rgb(77, 77, 77);
  height: 15%;
  border-top: 1px solid black;
}
.about__link{
  text-decoration: none;
  color: #696f79;
  text-align: center;
  font-size: 16px;
}
.about__link:hover{
  color: white;
}
.about__img{
  background-image: url(/static/media/black-marble2.a1b6492f.png);
  height: 15vh;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.about__imgK{
  background-image: url(/static/media/Kematen.9d3c70ad.png);
  height: 15vh;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
@media only screen and (max-width: 970px) {
.footer__container{
  min-height: 40vw!important;
  max-height: 50vw!important;
}}
@media only screen and (max-width: 760px) {
  .about__para{
    font-size: 2vw!important;
  }
  .footer__container{
    min-height: 40vw!important;
    max-height: 65vw!important;
  }
  .about__headerfooter{
    font-size: 2.5vw!important;
  }
  .footer__sociallinks h3{
    font-size: 2.5vw!important;
  }
}
@media only screen and (max-width: 590px) {

  .footer__copyright{
    font-size: 2vw;
  }


}
.form__component{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: baseline;
    width: 80%;
}
h4{
    color: #696F79;font-weight: 500;
    font-size: 18px;
}
.form__namenumber{
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.form__input{
background: #FFFFFF;
border: 1px solid #1565D8;
box-sizing: border-box;
box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.11);
border-radius: 6px;
width:  30vw;
height: 6vh;
outline-width: 0;
margin-bottom: 5vh;
padding: 1%;
}
.form__input:focus{
    border: 1px solid #FF7379;
}
.PhoneInputCountry{
    min-width: 25%!important;
}
.PhoneInputInput{
    border: none!important;
    outline-width: 0!important;
}
.form__weekcontainer{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 50%;
    flex-direction: column;
    margin-bottom: 5vh;
}
.form__weekscontainer h4{
    color: #525252;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 1vh;
}

.shop__opens{
    margin-bottom: 2vh;
}
.form__weeks {
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: center;
}
.form__timecontainer{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 40%;
    align-items: flex-start;
    margin-bottom: 5vh;
}
.form__aboutshop{
background: #FFFFFF;
border: 1px solid #1565D8;
box-sizing: border-box;
box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.11);
border-radius: 6px;
width:  100%;
height: 20vh;
outline-width: 0;
margin-bottom: 5vh;
padding-left: 1%;
}
.form__websitelink{
    height: 6vh;
}
.submit__btn{
background: #FF7379;
border-radius: 6px;
color: white;
border: none;
outline-width: 0;
height: 5vh;
width: 5vw;
cursor: pointer;
}
.form__weekscontainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
}
.form__servicecontainer{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 5vh;
}
.form__servicecontainer h4{
    color: #525252;
    font-weight: 500;
    font-size: 16px;
    margin-right: 2vh;
}
.form__services{
    display: flex;
    justify-content: center;
    align-items: center;
}
.form__error{
height: 20vh;
display: flex;
align-items: center;
justify-content: center;
width: 100%;
font-size: large;
color: red;
}
@media only screen and (max-width: 1030px) {
    .form__namenumber{
        flex-direction: column;
        justify-content: center;
        width: 100%;
        align-items: center;
    }
    .form__input{
        width: 100%;
    }
    .location__container{
        width: 100%;
    }
    .form__weekcontainer{
        margin-top: 5vh;
        width: 100%;
    }
    .form__timecontainer{
        width: 100%;
    }
    .submit__btn{
        width: 100px;
    }
  }
  @media only screen and (max-width: 945px){
      .form__servicecontainer{
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;
      }
      .form__services{
          width: 50%;
          display: flex;
          justify-content: space-between;
      }
      
  }
  @media only screen and (max-width: 560px){
    .form__services{
        width: 80%;
    }
    
}

.locationbar__containerform{
    position: relative;
    background: #FFFFFF;
    border: 1px solid #1565D8;
    box-sizing: border-box;
    box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.11);
    border-radius: 6px;
    width:  30vw;
    height: 6vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    outline-width: 0;
    padding-left: 1%;
}

.search__locationbarform{
    border: none;
    height: 100%;
    font-weight: normal;
    font-size: 1em;
    color: #656363;
    outline-width: 0;
   width: 100%;
    position: relative;
    border-radius: 6px;
    
}
.wrapperform{
    height: 100%;
    width: 85%;
    max-width: 90%;
}

.suggestion__containerform{
    background-color: white;
    max-width: 25vw;
    width: 25vw;
    padding:5px;
}
@media only screen and (max-width: 1024px) {
    .locationbar__containerform{
        width: 100%;
    }
  
  }

.admin__background{
    background-image: url(/static/media/admin__background.58494908.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 30vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: -2;
}
.admin__bgfilter{
    z-index: -1;
position: absolute;
height: 100%;
width: 100%;
background: linear-gradient(180deg, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0) 90.94%);
}
.admin__background h2{
font-family: Inter;
width: 30%;
font-style: normal;
font-weight: bold;
font-size: 4.278074866310161vw;
line-height: 4.078074866310161vw;
color: #FF7379;
}
.admin__background span{
    color:white;
    line-height: 4.078074866310161vw;
}
.admin__formcontainer{
    margin-top: 5vh;
    padding-left: 5vw;
    padding-right: 5vw;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 10vh;
}
.not-found{
    padding-top: 10vh;
    padding-left: 5vw;
    padding-right: 5vw;
    padding-bottom: 5vh;
    display: flex;
    flex-direction: column;
    min-height: 40vh;
    display: flex;
    flex-direction: column;
    min-height: 80vh;
    text-align: justify;
    color: rgb(19, 19, 19);
}
.not-found h1{
    text-align: center;
    font-size: 40px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    color: #696F79;
    text-transform: uppercase;
    margin-bottom: 10vh;
}
.not-found h6{
    color: rgb(56, 56, 56);
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    text-align: center;
    margin-bottom: 2vh;
}
.not-found h2{
    color: rgb(56, 56, 56);
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    text-align: center;
    margin-bottom: 2vh;
    margin-top: 5vh;
}
.not-found h3{
    color: #696F79;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    text-align: center;
    margin-top: 1vh;
    font-weight: 400;
    text-transform: capitalize;
}
