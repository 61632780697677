@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap');
.team__container{
    padding-top: 10vh;
    padding-left: 5vw;
    padding-right: 5vw;
    padding-bottom: 5vh;
    display: flex;
    flex-direction: column;
    min-height: 80vh;
    text-align: justify;
    color: rgb(19, 19, 19);
}
.team__container h1{
    text-align: center;
    font-size: 40px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    color: #696F79;
    text-transform: uppercase;
    margin-bottom: 5vh;
}
.team__container h6{
    color: rgb(56, 56, 56);
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    text-align: center;
    margin-bottom: 2vh;
}
.team__description h4{
    text-align: center;

}
.team__card{
    width: 250px;
    margin-left: 5vw;
    margin-right: 5vw;
}
.team__peter{
background-image: url("../../assets/peter.jpg");
height: 250px;
background-size: cover;
background-position: center;
background-repeat: no-repeat;
}
.team__carina{
    background-image: url("../../assets/DCADE1429.JPG");
    height: 250px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.team__cardscontainer{
    display: flex;
    justify-content: center;
}
@media only screen and (max-width: 785px) {
    .team__container h6{
        font-size: 12px;

    }
    .team__container h4{
        font-size: 10px;
    }
  }
  @media only screen and (max-width: 630px) {
    .team__container h6{
        font-size: 12px;

    }
    .team__container h4{
        font-size: 10px;
    }
    .team__image{
height: 25vw;
    }
  }