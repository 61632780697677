.search__container{
    display: flex;
    width: 100%;
    min-height: 70vh;
}
.search__aside{
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: row;   
}
.search__spacer{
    height: 15vh;
    background-color: #F6F7F8;
}
.search__components{
    width: 100%;
}
.search__components h2{
padding-top: 5vh;
padding-bottom: 5vh;
font-size: 18px;
line-height: 21px;
text-decoration: underline;
color: #000000;
}
.search__aside{
    padding-top: 5vh;
    padding-left: 2vw;
    padding-right: 2vw;
}
@media only screen and (max-width: 940px) {
    .search__aside{
        width: 35%;
    }
  }
  @media only screen and (max-width: 800px) {
    .search__aside{
        width: 250px;
    }
    .search__spacer{
        height:8vh;
        background-color: #F6F7F8;
    }
  }