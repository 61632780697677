.shopservice__container{
    margin-left: 5vw;
    margin-right: 5vw;
}
.shopservice__container h4{
color: #696F79;
font-weight: bold;
font-size: 18px;
margin-top: 5vh;
}
.service__container{
    display: flex;
    justify-content: space-between;

}
.service__details{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 5vh;
}
.service__icons{
    font-size: 1.5rem;
    margin-left: 1vw;
    color: green;
}
.service__preicon{
    color: #0070C0;
}
.service__title{
    margin-left: 1vw;
    
}
@media only screen and (max-width: 995px) {
    .service__container{
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .service__title{
        font-size: 1.8vw;
    }
    }