/* .drop__down{
    position: absolute;
    width: 20vw;
    min-width: 20vw;
    right: 4vw;
    top:15vh;
    padding: 2vw;
    background-color: #1c2331;
    color: white;
    z-index: 2;
}
.navbar__linkcontainer{
display: flex;
flex-direction: column;
justify-content: space-around;
height: 100%;
width: 100%;
}
.marketplace__btn{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-decoration: none;
    background-color: #FF7379;
    color: white;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    border-radius: 6px;
    height: 5vh;
    cursor: pointer;
    margin-top: 5vh;
}
.navlink__btn{
    display: flex;  
    justify-content: center;
    align-items: center;
    width: 100%;
    text-decoration: none;
    color: white;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    height: 5vh;
    cursor: pointer;
    margin-bottom: 5px;
    border-bottom: 1px solid #FF7379;
}
.navlink__btn:hover{
    color: #FF7379;
} */
.locationbar__container{
    position: absolute;
    z-index: 2;
    /* height: 7vh;
    width: 20vw; */
    top: 120%;
    left: 40%;
}
.navbar__menu{
    display: flex;
    justify-content: center;
    align-items: center;
}