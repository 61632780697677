.slide__bg1{
    background-image: url("../../assets/slide__1.jpeg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.slide__bg2{
    background-image: url("../../assets/slide__2.jpeg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.slide__bg3{
    background-image: url("../../assets/slide__3.jpeg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.slide__bg4{
    background-image: url("../../assets/slide__4.jpeg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}