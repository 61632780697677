@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
.shop__container{
    display: flex;
    position: relative;
    min-height: 30vh;
    height: 30vh;
    margin-bottom: 5vh;
    width: 100%;
}
.shop__image{
    position: relative;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 30%;
    /* background-image: url("../../../assets/search__background.png"); */
}
.shop__discription{
    margin-right: 5vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 5vw;
    width: 100%;
}
.shop__discription h2{
font-family: 'Poppins', sans-serif;
font-weight: 500;
font-size: 24px;
line-height: 36px;
color: #22262A;
}
.shop__discription p{
font-family: 'Poppins', sans-serif;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 18px;

color: #686868;
}

.shop__discription h4{
    overflow: hidden;
 text-overflow: ellipsis;
 display: -webkit-box;
 -webkit-line-clamp: 3;
 -webkit-box-orient: vertical;
    font-size: 14px;
    line-height: 21px;
    color: #262626;
}
.shop__visit{
    margin-top: 2vh;
background: rgba(51,160, 255, 0.1);
color: #1F7AC9;
font-family: 'Poppins', sans-serif;
font-size: 14px;
min-width: 20%;
width: 20%;
text-align: center;
font-weight: 500;
text-decoration: none;
height: 20%;
display: flex;
align-items: center;
justify-content: center;
}
@media only screen and (max-width: 932px) {
   .shop__container{
       min-height: 20vh;
       
   }
   .shop__discription h1{
       font-size: 20px;
   }
   .shop__discription h4{
       font-size: 12px;
   }
   .shop__visit{
       min-width: 40%;
   }
   .shop__image{
    width: 45%;
}
  }

  @media only screen and (max-width: 685px) {
    .shop__container{
        min-height: 20vh;
    }
    .shop__discription h1{
        font-size: 18px;
    }
    .shop__discription p{
        font-size: 10px;
      
    }
    .shop__discription h4{
        font-size: 10px;
        line-height: 18px;
    }
    .shop__visit{
        min-width: 60%;
    }
    
    
   }
   @media only screen and (max-width: 570px) {
    .shop__container{
        min-height: 20vh;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .shop__discription {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
    .shop__discription h1{
        font-size: 18px;
        text-align: center;
    }
    .shop__discription p{
        font-size: 10px;
        text-align: center;
        line-height: 12px;
    }
    .shop__discription h4{
        font-size: 10px;
        text-align: center;
        line-height: 12px;
        width: 80%;
    }
    .shop__visit{
        min-width: 80%;
        text-align: center;
    }
    .shop__image{
        width: 80%;
    }
   
   }
   @media only screen and (max-width: 420px) {
    .shop__discription h1{
        font-size: 15px;
    }
    .shop__discription p{
        font-size: 10px;
        text-align: center;
        line-height: 12px;
    }
    .shop__discription h4{
        font-size: 10px;
        text-align: center;
        line-height: 14px;
    }
    .shop__visit{
        min-width: 80%;
        font-size: 10px;
        text-align: center;
    }
    .shop__image{
        width: 80%;
    }
   
   }