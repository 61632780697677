@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap');

.DP__container{
    padding-top: 10vh;
    padding-left: 5vw;
    padding-right: 5vw;
    padding-bottom: 5vh;
    display: flex;
    flex-direction: column;
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    min-height: 80vh;
    text-align: justify;
    color: rgb(19, 19, 19);
}
.DP__container h1{
    text-align: center;
    font-size: 40px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    color: #696F79;
    text-transform: uppercase;
    margin-bottom: 5vh;
}
.DP__container h6{
    color: rgb(56, 56, 56);
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    text-align: center;
    margin-bottom: 2vh;
}
.DP__container h2{
    color: rgb(56, 56, 56);
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    text-align: center;
    margin-bottom: 2vh;
    margin-top: 5vh;
}
.DP__container h3{
    color: #696F79;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    text-align: center;
    margin-top: 1vh;
    font-weight: 400;
    text-transform: capitalize;
}
  @media only screen and (max-width: 590px) {
    .DP__container  h1{
        font-size:25px;
    }
  }