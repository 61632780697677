.menu__container{
    margin-top: 5vh;
    width: 60%;
    position: relative;
    
}
.home__category{
    text-transform: capitalize;
}
.menu__categories{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.menu__container button{
    text-transform: capitalize;
    font-family: Poppins;
    font-size: 22px;
    color: #FFFFFF;
    background-color: transparent;
    outline-width: 0px;
    cursor: pointer;
    text-decoration: none;
    border: none;
}
.menu__container button:hover{
color: #FF7379;
}
.menu__allcats{
    position: absolute;
    bottom: -75vh;
    right: 0;
    background-color: white;
    padding: 5%;
    width: 60vw;
    height: 70vh;
    min-height: 30vh;
    z-index: 2;
    display: flex;
    justify-content: space-around;
    box-shadow: 8px 11px 62px -19px rgba(0,0,0,0.46);
    -webkit-box-shadow: 8px 11px 62px -19px rgba(0,0,0,0.46);
    -moz-box-shadow: 8px 11px 62px -19px rgba(0,0,0,0.46);
}
.menu__allcats button{
    color: #262626;
    text-align: left;
}
.row{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
}
@media only screen and (max-width: 1262px) {
    .menu__container button{
        font-size: 16px;
    }
    .menu__container{
        width: 80%;
     }
  }
@media only screen and (max-width: 932px) {
    .menu__container button{
        font-size: 16px;
    }
    
  }
  @media only screen and (max-width: 690px) {
    .menu__container button{
        font-size: 13px;
    }
  }
  @media only screen and (max-width: 690px) {
   .menu__allcats{
       flex-direction: column;
       width: 35vw;
       min-height: 40vh;
       padding: 4%;
       margin-right: 1%;
   }
   .menu__container{
    width: 100%;
   }
  }
  @media only screen and (max-width: 506px) {
  
    .menu__container button{
        font-size: 10px;
        justify-content: space-evenly;
    }
    .menu__container{
        width: 95%;
     }
   }
   @media only screen and (max-width: 400px) {
  
    .menu__container button{
        font-size: 8px;
        justify-content: space-evenly;
    }
    .menu__container{
        width: 95%;
     }
   }
 