*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* border: 1px solid #f00 !important; */
  outline-width: 0!important;
}

body{
  width: 100%;
}
