@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@600&display=swap');

.home__container{
    position: relative;
    padding-top: 20vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column  ;
    max-width: 100vw;
}
.home__backgroundImage{
    /* background-image: url("../assets/category_svg_bg.svg"); */
    height: 76.52519893899205vh;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    max-width: 100%;
    z-index: -2;
    position: absolute;
    background-position: center;
    top:0;
    left: 0;
}
.home__decor{
    position: absolute;
    z-index: -1;
    background: linear-gradient(359.9deg, #000000 14.14%, rgba(0, 0, 0, 0) 102.14%);
    height: 76.52519893899205vh;
    width: 100%;
    max-width: 100%;
    top:0;
    left: 0;
}
.home__category{
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 400%;
    color: white;
    text-align: center;
}
.home__herocontainer{
    width: 100%;
    max-width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow-x: hidden;
}
.home__cardcontainer{
   width: 78.34224598930481vw;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 5vh;
    column-gap: 5.481283422459893vw;
    min-height: 40vh;
}
.home__loadmore{
font-weight: 500;
font-size: 20px;
line-height: 30px;
color: #FF7379;
border: none;
border-bottom: 1px solid #FF7379;
background-color: transparent;
cursor: pointer;
outline-width: 0;
}
.contribute_shopcontainer{
    margin-top: 5vh;
    height: 5vh;
    width: 80%;
    display: flex;
    justify-content: flex-end;
}
.contribute__link{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    text-decoration: none;
    background-color: #FF7379;
    color: white;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    border-radius: 6px;
}
.home__selectlocation{
    display: flex;
    flex-direction: column;
    background-color: white;
    justify-content: space-around;
    align-items: center;
    width: 80vw;
    height: 30vh;
    box-shadow: 2px -1px 42px -6px rgba(0,0,0,0.75);
-webkit-box-shadow: 2px -1px 42px -6px rgba(0,0,0,0.75);
-moz-box-shadow: 2px -1px 42px -6px rgba(0,0,0,0.75);
}
.home__selectlocation h4{
    
}
@media only screen and (max-width: 1025px){
    .home__backgroundImage,
    .home__decor{
        height: 50vh;
    }
  } 
@media only screen and (max-width: 932px) {
    .home__category{
       
        font-size: 300%;
        
    }
  }
  @media only screen and (max-width: 690px) {
    .home__category{
       
        font-size: 200%;
        
    }
    .home__cardcontainer{
        grid-template-columns: 1fr 1fr;
    }
    .home__backgroundImage{
        height: 50vh;
      
    }
    .home__decor{
        height: 50vh;
    }
  }
  @media only screen and (max-width: 767px) {
    .contribute__link{
       font-size: 1.5vw;
    }
  }
  @media only screen and (max-width: 477px) {
    .contribute__link{
       font-size: 1.8vw;
    }
  }
